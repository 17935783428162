<template>
  <v-container fluid>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="terminals"
        :loading="loading"
        :options.sync="options"
        :server-items-length="getTerminalsTotalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20], 'show-current-page': true, 'show-first-last-page': true }"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar color="indigo lighten-5" flat>
            <BackButton />
            <v-toolbar-title>Термінали</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchText"
              class="shrink mr-10"
              append-icon="mdi-magnify"
              label="Пошук"
              single-line
              hide-details
            ></v-text-field>
            <v-dialog v-model="dialogConnect" max-width="1000">
              <v-card>
                <v-card-title>
                  <span class="text-h5">
                    Підключити термінал <span class="font-weight-bold">T{{ clickedTerminalId }}</span> до точки
                  </span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-sheet class="d-inline-block pa-3" color="grey lighten-3">
                          <h3>T{{ clickedTerminalId }}:</h3>
                          <div>
                            IMEI: <span class="font-weight-medium">{{ clickedTerminal.imei }}</span>
                          </div>
                          <div>
                            Виробник: <span class="font-weight-medium">{{ clickedTerminal.producer }}</span>
                          </div>
                          <div>
                            Модель: <span class="font-weight-medium">{{ clickedTerminal.model }}</span>
                          </div>
                          <div>
                            Місце розташування: <span class="font-weight-medium">{{ clickedTerminal.location }}</span>
                          </div>
                        </v-sheet>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <PagingSelect
                          :options="servicePoints"
                          :options-meta="servicePointsMeta"
                          :options-params="servicePointsOptionsData"
                          item-value="id"
                          item-text="name"
                          @change="setServicePoint"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="selectedLocation"
                          :items="servicePoint.location"
                          item-value="value"
                          label="Оберіть розташування підключенного термінала"
                          :disabled="!servicePoint || !servicePoint.location"
                          :error-messages="inputErrors('selectedLocation')"
                          @input="$v.selectedLocation.$touch()"
                          @blur="$v.selectedLocation.$touch()"
                        />
                        <v-dialog v-model="dialogAddLocation" max-width="500">
                          <template v-slot:activator="{ on, attr }">
                            <v-btn v-bind="attr" v-on="on" :disabled="!servicePoint || !servicePoint.location"
                              >Додати розташування</v-btn
                            >
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="text-h5"> Додати розташування до точки </span>
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-text-field v-model="newLocation" label="Вкажіть назву нового розташування"></v-text-field>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" text @click="addLocation">Так</v-btn>
                              <v-btn text @click="dialogAddLocation = false">Нi</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="saveConnect">Так</v-btn>
                  <v-btn text @click="closeDialogConnect">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on"> Додати термінал </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="editedItem.producer"
                          :items="PRODUCERS"
                          :error-messages="inputErrors('editedItem.producer')"
                          @input="$v.editedItem.producer.$touch()"
                          @blur="$v.editedItem.producer.$touch()"
                          label="Виробники"
                          dense
                        ></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          v-model="editedItem.model"
                          :items="terminalsModelsDictionary"
                          :error-messages="inputErrors('editedItem.model')"
                          @input="$v.editedItem.model.$touch()"
                          @blur="$v.editedItem.model.$touch()"
                          item-value="name"
                          item-text="description"
                          label="Моделі"
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <!--                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model.number="editedItem.bill_acceptor_capacity"
                          type="number"
                          label="Ємність купюроприймача"
                        ></v-text-field>
                      </v-col>-->
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="editedItem.imei"
                          :error-messages="inputErrors('editedItem.imei')"
                          @input="$v.editedItem.imei.$touch()"
                          @blur="$v.editedItem.imei.$touch()"
                          label="CB Id (IMEI)"
                        ></v-text-field>
                      </v-col>
                      <!--                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.location" label="Розташування"></v-text-field>
                      </v-col>-->
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="save()">Так</v-btn>
                  <v-btn text @click="close">Нi</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5 justify-center">Термінал буде видалено</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="deleteItemConfirm">Так</v-btn>
                  <v-btn text @click="closeDelete">Нi</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:header.service_point="{ header }">
          <TableTitleFilter
            :options="servicePoints"
            :options-meta="servicePointsMeta"
            :options-params="servicePointsOptionsData"
            :title-text="header.text"
            item-value="id"
            item-text="name"
            @select="(selected) => (selectedServicePoints = selected)"
            @ok="applyFilter('service_point.id', selectedServicePoints)"
          >
            <template v-slot:item="{ item }">
              <span v-if="item.address">
                {{ item.name }}, {{ item.address.country }}, {{ item.address.city }}, {{ item.address.street }},
                {{ item.address.building_number }}
              </span>
              <span v-else>{{ item.name }}</span>
            </template>
          </TableTitleFilter>
        </template>
        <template v-slot:header.devices="{ header }">
          <TableTitleFilter
            :options="devices"
            :options-meta="devicesMeta"
            :options-params="devicesOptionsData"
            :title-text="header.text"
            item-value="id"
            @select="(selected) => (selectedDevices = selected)"
            @ok="applyFilter('devices.id', selectedDevices)"
          >
            <template v-slot:item="{ item }">{{ getDeviceTitle(item.type) }} {{ item.id }}</template>
            <template v-slot:selection="{ item }">{{ getDeviceTitle(item.type) }} {{ item.id }}</template>
          </TableTitleFilter>
        </template>
        <template v-slot:item.id="{ item }">
          <router-link
            :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId: item.id } }"
            class="text-decoration-none"
          >
            <v-tooltip bottom :open-on-focus="false">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-if="item.status !== null && item.status !== 1"
                  :style="{ color: getListStatusColor(item.status) }"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.id }}
                </span>
                <span v-else v-bind="attrs" v-on="on">{{ item.id }}</span>
              </template>
              <span>{{ getListStatusTitle(item.status) }}</span>
            </v-tooltip>
          </router-link>
        </template>
        <template v-slot:item.service_point="{ item }">
          <router-link
            v-if="item.service_point"
            :to="{ name: 'ServicePointCard', params: { id: currentCompanyId, servicePointId: item.service_point.id } }"
            class="text-decoration-none"
          >
            {{ item.service_point.name }}, {{ item.service_point.address.country }}, {{ item.service_point.address.city }},
            {{ item.service_point.address.street }}, {{ item.service_point.address.building_number }}
          </router-link>
        </template>
        <template v-slot:item.devices="{ item }">
          <template v-if="item.devices && item.devices.length">
            <div v-for="device in item.devices" :key="device.id" class="my-2">
              <router-link
                :to="{ name: 'DeviceCard', params: { id: currentCompanyId, deviceId: device.id } }"
                class="text-decoration-none"
              >
                {{ getDeviceTitle(device.type) }} {{ device.id }}
              </router-link>
            </div>
          </template>
          <template v-else>
            <span class="font-weight-thin font-italic">не підключено</span>
          </template>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="!item.service_point && item.status === 1"
                @click="openConnectDialog(item)"
                v-bind="attrs"
                v-on="on"
                class="mr-2"
                small
              >
                mdi-connection
              </v-icon>
            </template>
            <span>Підключити</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on"> mdi-pencil-outline </v-icon>
            </template>
            <span>Редагувати</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" @click="deleteItem(item)" v-bind="attrs" v-on="on"> mdi-delete-outline </v-icon>
            </template>
            <span>Видалити</span>
          </v-tooltip>
          <v-tooltip bottom :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-icon small @click="navigateToTerminalCard(item)" v-bind="attrs" v-on="on"> mdi-eye-outline </v-icon>
            </template>
            <span>Переглянути</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="resetData">Оновити</v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { TERMINAL_SCHEMA } from '@/const/apiSchemas'
import { PRODUCERS } from '@/const/producers.enum'
import { mergeObjects } from '@/helpers/mergeObjects'
import ConvertStatusesTypes from '@/mixins/convertStatusesTypes'
import resetTableData from '@/mixins/resetTableData'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import BackButton from '@/components/common/BackButton'
import sortUtils from '@/mixins/sortUtils'
import filterUtils from '@/mixins/filterUtils'
import cloneDeep from '@/mixins/cloneDeep'
import TableTitleFilter from '@/components/common/filters/TableTitleFilter/TableTitleFilter'
import searchUtils from '@/mixins/searchUtils'
import convertDevicesTypes from '@/mixins/convertDevicesTypes'

export default {
  name: 'TerminalsList',

  components: {
    TableTitleFilter,
    BackButton,
    PagingSelect: () => import('@/components/common/PagingSelect'),
  },

  mixins: [
    validationMixin,
    resetTableData,
    ConvertStatusesTypes,
    convertDevicesTypes,
    cloneDeep,
    sortUtils,
    filterUtils,
    searchUtils,
  ],

  validations: {
    editedItem: {
      producer: { required },
      model: { required },
      imei: { required },
    },
    selectedLocation: { required },
  },

  data: () => ({
    PRODUCERS: PRODUCERS,
    dialog: false,
    dialogDelete: false,
    dialogAddLocation: false,
    loading: false,
    clickedTerminalId: null,
    clickedTerminal: {},
    selectedServicePoint: null,
    selectedLocation: null,
    dialogConnect: false,
    headers: [
      { text: 'ID', align: 'start', sortable: true, value: 'id' },
      { text: 'CB Id (IMEI)', value: 'imei', sortable: true },
      // { text: 'Ємність купюроприймача', value: 'bill_acceptor_capacity' },
      { text: 'Торгівельна точка', value: 'service_point', sortable: false },
      { text: 'Підключені автомати', value: 'devices', sortable: false },
      {
        text: '',
        value: 'actions',
        sortable: false,
        width: '125px',
        align: 'right',
      },
    ],
    editedIndex: -1,
    payload: null,
    sortBy: '',
    sortDesc: false,
    options: {},
    editedItem: {},
    defaultItem: { ...TERMINAL_SCHEMA },
    newLocation: '',
    searchFields: ['id', 'imei', 'service_point.name', 'service_point.address.city', 'service_point.address.street'],
  }),

  computed: {
    ...mapGetters('terminals', ['getTerminalsTotalCount']),
    ...mapState('terminals', ['terminals', 'terminal']),
    ...mapState('servicePoints', ['servicePoints', 'servicePointsMeta', 'servicePoint']),
    ...mapState('dictionaries', ['terminalsModelsDictionary', 'machinesTypesDictionary']),

    formTitle() {
      return this.editedIndex === -1 ? 'Створити термінал' : 'Редагувати термінал'
    },
    currentCompanyId() {
      return this.$route.params.id
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      handler() {
        this.paginateTo()
      },
      deep: true,
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapActions('terminals', [
      'loadTerminals',
      'createNewTerminal',
      'loadSelectedTerminal',
      'updateSelectedTerminal',
      'deleteSelectedTerminal',
    ]),
    ...mapActions('servicePoints', [
      'loadServicePoints',
      'addTerminalToServicePoint',
      'loadSelectedServicePoint',
      'updateSelectedServicePoint',
    ]),
    ...mapActions('logs', ['displayWarningAlert']),

    async initialize() {
      this.editedItem = this.cloneObjectDeep(TERMINAL_SCHEMA)
      this.payload = {
        companyId: this.currentCompanyId,
      }
      this.servicePointsOptionsData.payload.forSearch = 0
    },

    inputErrors(fieldName) {
      let field
      if (fieldName.includes('.')) {
        const [objectName, fieldPart] = fieldName.split('.')
        field = this.$v[objectName] && this.$v[objectName][fieldPart]
      } else {
        field = this.$v[fieldName]
      }
      const errors = []
      if (!field || !field.$dirty) return errors
      !field.required && errors.push('Це поле обов"язкове')
      return errors
    },

    async paginateTo() {
      if (!this.currentCompanyId) {
        await this.displayWarningAlert({ message: 'Оберiть компанiю' })
        await this.$router.replace('/spa')
      }
      this.loading = true
      this.payload = {
        ...this.payload,
        page: this.options.page,
        limit: this.options.itemsPerPage,
        sort: this.sortObject,
        search: this.searchObject,
        ...this.filterObject,
      }
      try {
        await this.loadTerminals(this.payload)
      } finally {
        this.loading = false
      }
    },

    editItem(item) {
      this.editedIndex = this.terminals.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      this.editedItem = mergeObjects(this.editedItem, item)
      this.clickedTerminalId = item.id
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.terminals.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.clickedTerminalId = item.id
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.loading = true
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.clickedTerminalId,
      }
      await this.deleteSelectedTerminal(payload)
      this.closeDelete()
      await this.paginateTo()
      this.loading = false
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save() {
      this.$v.editedItem.$touch()
      if (this.$v.$anyError) return
      this.loading = true
      if (this.editedIndex > -1) {
        const payload = {
          companyId: this.currentCompanyId,
          terminalId: this.clickedTerminalId,
          updatedTerminal: this.editedItem,
        }
        await this.updateSelectedTerminal(payload)
      } else {
        const payload = {
          companyId: this.currentCompanyId,
          newTerminal: this.editedItem,
        }
        await this.createNewTerminal(payload)
      }
      this.close()
      await this.loadTerminals({ companyId: this.currentCompanyId })
      await this.initialize()
      this.loading = false
    },

    openConnectDialog(item) {
      this.clickedTerminal = item
      this.clickedTerminalId = item.id
      this.dialogConnect = true
    },

    async saveConnect() {
      this.$v.selectedLocation.$touch()
      if (!this.$v.$anyError) {
        this.loading = true
        const params = {
          companyId: this.currentCompanyId,
          terminalId: this.clickedTerminalId,
          servicePointId: this.selectedServicePoint.id,
        }
        this.dialogConnect = false
        await this.addTerminalToServicePoint(params)
        if (this.selectedLocation) {
          const terminalParams = {
            companyId: this.currentCompanyId,
            terminalId: this.clickedTerminalId,
            updatedTerminal: {
              bill_acceptor_capacity: this.terminal.bill_acceptor_capacity,
              imei: this.terminal.imei,
              location: this.selectedLocation,
              producer: this.terminal.producer,
            },
          }
          await this.updateSelectedTerminal(terminalParams)
        }
        await this.paginateTo()
      }
    },

    navigateToTerminalCard(item) {
      const clickedTerminalId = item.id
      this.$router.push({
        name: 'TerminalCard',
        params: { id: this.currentCompanyId.toString(), terminalId: clickedTerminalId.toString() },
      })
    },

    async setServicePoint(item) {
      this.selectedServicePoint = item
      this.loading = true
      const payload = {
        companyId: this.currentCompanyId,
        servicePointId: item.id,
      }
      const terminalPayload = {
        companyId: this.currentCompanyId,
        terminalId: this.clickedTerminalId,
      }
      await this.loadSelectedServicePoint(payload)
      await this.loadSelectedTerminal(terminalPayload)
      this.loading = false
    },

    async addLocation() {
      if (this.newLocation) {
        this.loading = true
        const payload = {
          companyId: this.currentCompanyId,
          servicePointId: this.servicePoint.id,
          updatedServicePoint: { ...this.servicePoint, location: [...this.servicePoint.location, this.newLocation] },
        }
        delete payload.updatedServicePoint.id
        delete payload.updatedServicePoint.terminals
        await this.updateSelectedServicePoint(payload)
        this.selectedLocation = this.newLocation
        this.newLocation = null
        await this.setServicePoint(this.servicePoint)
        this.loading = false
        this.dialogAddLocation = false
      }
    },

    closeDialogConnect() {
      this.dialogConnect = false
      this.$v.$reset()
      this.$nextTick(() => {
        this.selectedServicePoint = null
        this.selectedLocation = null
      })
    },
  },
}
</script>

<style scoped></style>
